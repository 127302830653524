<template>
  <div class="box">
    <template v-if="hostname !== 'jykw.gz-jiayou.com'">
      <div class="top">
        <div class="top_content" v-if="info">
          <img v-if="info.logo" :src="info.logo" class="logo" alt="" />
          <h1>{{ info.name }}职业技能等级认定考核系统</h1>
        </div>
      </div>
      <div class="center">
        <img
          :src="banner ? banner : require('../../assets/login_bg.jpeg')"
          alt=""
        />

        <div class="router_box">
          <div class="form_box">
            <router-view></router-view>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="left" v-if="info">
          <p>客服电话：{{ info.phone }}</p>
          <p>公司地址：{{ info.address }}</p>
          <!-- <p>
            热门链接：
            <a href="https://weibo.com/" target="_blank" rel="noopener noreferrer"
              ><span>微博</span></a
            >
            <a
              href="https://www.zhihu.com/"
              target="_blank"
              rel="noopener noreferrer"
              ><span>知乎</span></a
            >
            <a
              href="https://www.baidu.com/"
              target="_blank"
              rel="noopener noreferrer"
              ><span>百度</span></a
            >
          </p> -->
        </div>
      </div>
      <div class="footer" v-if="hostname != 'kone.gz-jiayou.com'"></div>
      <p class="foot_bottom" v-if="hostname != 'kone.gz-jiayou.com' && hostname !== 'www.gmi.org.cn'">
        Copyright ©2017-2021尚考嘉, All Rights Reserved ICP认证：<span class="span_yue" @click="toYue">粤A2-20100541</span>
      </p>
      <p class="foot_bottom" v-if="hostname == 'www.gmi.org.cn'">
        Copyright ©2017-2021尚考嘉, All Rights Reserved ICP认证：<span class="span_yue" @click="toYue">粤ICP备15063954号</span>
      </p>
    </template>
    <template v-else>
      <div class="center" style="height: 100vh;">
        <img
          :src="banner ? banner : require('../../assets/login_bg.jpeg')"
          alt=""
        />

        <div class="router_box" style="top:25%;">
          <div class="form_box">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </template>
  </div>
  <!-- <div class="login_index">
    <div
      class="bg"
      v-if="bg"
      :style="
        bg && bg.length != 0
          ? 'background-image: url(' + bg[0].picUrl + ')'
          : ''
      "
    ></div>
    <div class="login_box">
      <div class="logo">
        <img :src="logo" alt="" />
      </div>

      <div class="form_box">
        <span class="back_box" @click="backIndex"
          ><i class="el-icon-d-arrow-left"></i>返回</span
        >
        <div class="login_form">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <p class="foot">
      Copyright ©2017-2021尚考嘉, All Rights Reserved ICP认证：粤A2-20100541
    </p>
  </div> -->
</template>

<script>
import {companyInfo} from "@/util/api";
export default {
  data() {
    return {
      info: {},
      banner: "",
      hostname:window.location.hostname
    };
  },
  created() {
    companyInfo({domainUrl: window.location.hostname})
      .then((res) => {
        this.info = res.data;
        if (this.info.loginPicList.length != 0) {
          this.banner = this.info.loginPicList[0].picUrl;
        }
      })
      .catch((err) => {});
  },
  methods: {
    backIndex() {
      console.log("in123");
      this.$router.push("/");
    },
    toYue(){
      window.open('https://beian.miit.gov.cn/')
    }
  },
};
</script>

<style lang="scss" scoped>
.el-button--primary {
  color: #fff;
  background-color: #515a6e;
  border-color: #515a6e;
}
.top {
  width: 100%;
  height: 128px;
  background: #ffffff;
  display: flex;
  align-items: center;

  .top_content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  h1 {
    font-size: 30px;
    font-weight: 900;
    color: #2b2b2b;
    margin-left: 20px;
  }
  .logo {
    width: 50px;
    height: 50px;
  }
}
.span_yue:hover {
  // color: #007af0;
  text-decoration: underline;
}
.center {
  width: 100%;
  // height: 100vh;
  position: relative;
  height: 588px;
  overflow: hidden;
  img {
    width: 100%;
    // height: calc(100vh - 0px);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .router_box {
    width: 1200px;
    left: 50%;
    margin-left: -600px;
    position: absolute;
    top: 15%;
    display: flex;
    justify-content: flex-end;
    .form_box {
      width: 532px;
      box-shadow: 0px 1px 14px 6px #cecece;
      height: auto;
      background-color: #ffffff;
    }
  }
}
.bottom {
  width: 1200px;
  margin: 0 auto;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    p {
      font-size: 20px;
      color: #0a0a0a;
      margin-bottom: 22px;
      span {
        color: #515a6e;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
  .right {
    width: 128px;
    height: 128px;
    background-color: #2b2b2b;
  }
}
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 28px;
  background: #4b4b4b;
}
.foot_bottom {
  position: fixed;
  text-align: center;
  width: 100%;
  background-color: #4b4b4b;
  font-size: 12px;
  line-height: 38px;
  color: #ffffff;
  bottom: 0;
  cursor: pointer;
}
</style>
